<template>
    <div style="width:100%">
        <div>
            <el-input style="width:80%" v-model="messageAll"></el-input>
            <el-button @click="sendAll" type="primary">所有人发送</el-button>
        </div>
        <div class="examAdminMobile">
            <div v-for="item in views" :key="item._id.$id" class="item" @click="init(item)">
                <div :id="item._id.$id" class="video"></div>
                <div :id="item.student_id" class="video"></div>
                <div>{{item.student_name}}</div>
                <div>{{item.account_name}}</div>
            </div>
            <el-dialog title="发送消息" :visible.sync="showMessage">
                <div>{{name}}</div>
                <el-input v-model="message"></el-input>
                <el-button type="primary" @click="sendMessage">发送</el-button>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import * as QNRTC from 'pili-rtc-web'
import {getApplicationsByRoom,createRoomToken} from '../../api/index'
import { getUserId } from '../../utils/store'
const myRoom = new QNRTC.TrackModeSession()
export default {
    data(){
        return{
            roomId:this.$route.query.roomId,
            views:[],
            name:'',
            userId:'',
            message:'',
            showMessage:false,
            messageAll:''
        }
    },
    async mounted(){
        await this.fetchData()
        this.joinRoom()
    },
    methods:{
        init(item){
            this.name = item.student_name
            this.userId = item._id.$id
            this.message = ''
            this.showMessage = true
        },
        fetchData(){
            return new Promise(resolve=>{
                getApplicationsByRoom(this.roomId).then(res=>{
                    if(res.data.code===0){
                        this.views = res.data.data
                        resolve(true)
                    }
                })
            })
        },
        async joinRoom () {
            let roomToken
            const result = await this.createRoomToken()
            if(result.code==0){
                roomToken = result.data.token
            }else{
                this.$message(result.msg)
                return;
            }
            try {
                // 加入房间
                await myRoom.joinRoomWithToken(roomToken)
                this.$message({
                    message:'房间加入成功',
                    type:'success'
                })
                // 如果加入房间后发现房间已经有人发布，就订阅他
                this.subscribeTracks(myRoom.trackInfoList)
            } catch (e) {
                console.error(e)
                this.$message(`加入房间失败！ErrorCode: ${e.code || ''}`)
                return
            }
            myRoom.on("track-add", async(tracks) => {
                this.subscribeTracks(tracks);
            });
         
        },
        createRoomToken () {
            return new Promise((resolve,reject)=>{
                createRoomToken(this.roomId,getUserId(),'admin').then(res => {
                    resolve(res.data)
                })
            })
        },
        subscribeTracks (trackInfoList) {
            console.log(1111111111111,trackInfoList)
            myRoom.subscribe(trackInfoList.map(t => t.trackId)).then(tracks => {
                for (const track of tracks) {
                    let main = document.getElementById(track.userId);
                    track.play(main);
                }
            });
        },
        async sendMessage(){
            await myRoom.sendCustomMessage(this.message,[this.userId]);
            this.$message({
                message:'发送成功',
                type:'success'    
            })
            this.showMessage = false
        },
        async sendAll(){
            await myRoom.sendCustomMessage(this.message);
            this.$message({
                message:'发送成功',
                type:'success'    
            })
            
        },
    }
}
</script>

<style lang="less">
.examAdminMobile{
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    .item{
        cursor: pointer;
        text-align: center;
        border: 1px solid white;
        .video{
            width: 280px;
            height: 180px;
            background: black;
        }
        .qnrtc-stream-player {
            width: 100% !important;
          
            /* height: 1080px !important; */
            background: #000;
        }

    }
}
</style>